import type { ActionFunctionArgs, HeadersFunction, LoaderFunctionArgs, MetaFunction } from '@remix-run/node';
import { json, redirectDocument } from '@remix-run/node';
import { useEffect } from 'react';
import { useActionData, useLoaderData } from '@remix-run/react';
import { z } from 'zod';
import { zfd } from 'zod-form-data';
import { withZod } from '@remix-validated-form/with-zod';
import { ValidatedForm, validationError } from 'remix-validated-form';
import { validateQueryParams } from '~/utils/parameters.server';
import { AuthorizationError } from '~/clients/AuthApiClient';
import { ValidatedInput } from '~/components/forms/ValidatedInput';
import { SubmitButton } from '~/components/forms/SubmitButton';
import { LoginError, loginService, webfingerService } from '~/services/login.server';
import { environment } from '~/environment';
import i18next from '~/localization/i18next.server';
import getDefaultLocale from '~/localization/get-default-locale.server';
import { useTranslationScope } from '~/localization/use-translation-scope';
import { resolveAuthorizeUrl } from '~/utils/authorize-url.server';
import { getRememberedUser, setRememberedUser } from '~/remember-user.server';
import { PreserveQueryLink } from '~/components/PreserveQueryLink';
import Sentry, { captureException } from '@sentry/remix';
import { AuthnRedisError } from '~/services/expired-password.server';

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
    { title: data?.title || "Moody's Login" },
    { name: 'description', content: data?.description || "Login with your Moody's Account" },
  ];
};

const TRANSLATION_NAMESPACE = 'login';

let emailSchema = zfd.text(z.string().email({ message: 'please-enter-a-valid-email' }));

const identifySchema = z.object({
  subaction: z.literal('identify'),
  email: emailSchema,
  rememberMe: z.string().default('false'),
});

const loginSchema = z.object({
  subaction: z.literal('login'),
  email: emailSchema,
  password: zfd.text(),
  rememberMe: z.string().default('false'),
});

const authSchema = z.discriminatedUnion('subaction', [identifySchema, loginSchema]);
const authValidator = withZod(authSchema);

export let handle = {
  // Set the namespace for the copy needed for this route
  i18n: TRANSLATION_NAMESPACE,
};

const cacheControlHeaders = {
  'Cache-Control': 'no-cache, no-store',
};

// Only runs on document requests until we upgrade to single fetcher or react router 7
export const headers: HeadersFunction = () => ({
  ...cacheControlHeaders,
});

/* Login scenarios:
 * 1. Non-federated user comes to login via /authorize. They provide an email address and on submit we confirm they are non-federated via webfinger in the action. They can then proceed to enter their password.
 * 2. Federated user comes to login via /authorize. They provide an email address and on submit we confirm they are federated via webfinger. We redirect them to the authorize endpoint with login_hint.
 * 3. User comes to login via /authorize with a login_hint. We confirm they are non-federated via webfinger in the loader and they can proceed to enter their password directly.
 * 4. User comes to login via /authorize with a login_hint. The user is recognized as federated via webfinger in the loader and we pre-populate their email.
 *    a. THIS IS AN INVALID SCENARIO -- The initial request to `/authorize` _should_ have read their login hint, detected they were federated, and bypassed this login UI entirely.
 *    b. We log details of the request but allow them to click to proceed with the pre-populated email.
 * 5. User comes to login via /authorize with a login_hint and a remember me cookie. We confirm they are non-federated via webfinger in the loader and we proceed to enter their password directly.
 *    a. The login_hint takes precedence over the remember me cookie - the user will need to check 'Remember Me' to remember their current email.
 * */
export async function loader({ request, context }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const { error, data: params } = validateQueryParams(url.searchParams);

  if (error !== null) {
    context.log(error);
    return redirectDocument(environment.get('SSO_V2_FALLBACK_REDIRECT'), { headers: { ...cacheControlHeaders } });
  }

  const { rememberedUser } = await getRememberedUser(request);

  let type: 'password' | null = null;
  let defaultEmail = rememberedUser ?? '';

  if (params.loginHint && emailSchema.safeParse(params.loginHint).success) {
    const idpResult = await webfingerService({ email: params.loginHint, clientId: params.clientId, context });

    defaultEmail = params.loginHint;

    if (!idpResult) {
      type = 'password';
    } else {
      Sentry.withScope(() => {
        Sentry.setTag('clientId', url.searchParams.get('client_id') ?? '');
        Sentry.setTag('redirectUri', url.searchParams.get('redirect_uri') ?? '');
        Sentry.captureMessage('Federated user attempted to login with a login_hint');
      });
      context.log('Federated user attempted to login with a login_hint');
    }
  }

  const locale = await getDefaultLocale(request);
  const t = await i18next.getFixedT(locale, TRANSLATION_NAMESPACE);

  return json(
    {
      title: t('moodys-login'),
      description: t('login-with-your-moodys-account'),
      locale,
      isUserRemembered: !!rememberedUser && !params.loginHint,
      type,
      defaultEmail,
    },
    {
      headers: { ...cacheControlHeaders },
    },
  );
}

export async function action({ request, context }: ActionFunctionArgs) {
  if (request.method !== 'POST') {
    return new Response(null, { status: 405 });
  }

  const url = new URL(request.url);
  const { error, data: params } = validateQueryParams(url.searchParams);

  if (error || !params) {
    return json({ error }, 400);
  }

  const validationResult = await authValidator.validate(await request.formData());

  if (validationResult.error) {
    return validationError(validationResult.error);
  }

  const data = validationResult.data;

  // Set-Cookie header for updating the remember me cookie
  const setRememberMeCookie = {
    'Set-Cookie': await setRememberedUser(request, data.rememberMe === 'true' ? data.email : ''),
  };

  if (data.subaction === 'identify') {
    const idpResult = await webfingerService({
      email: validationResult.data.email,
      clientId: params.clientId,
      context,
    });
    if (idpResult) {
      // Redirect the user to the authorize endpoint with login_hint
      const authUrl = resolveAuthorizeUrl(url, validationResult.data.email);

      return redirectDocument(authUrl, {
        headers: { ...setRememberMeCookie, ...cacheControlHeaders },
      });
    }

    return json(
      {
        email: data.email,
        type: 'password',
      },
      {
        headers: { ...setRememberMeCookie, ...cacheControlHeaders },
      },
    );
  } else if (data.subaction === 'login') {
    try {
      context.log('subaction: login');
      const { redirectUrl, headers } = await loginService(url, data.email, data.password, context);

      const responseHeaders = new Headers(headers);
      // merging the objects could overwrite the headers if they are the same
      // using append to ensure we are not overwriting the headers
      responseHeaders.append('Set-Cookie', setRememberMeCookie['Set-Cookie']);
      responseHeaders.append('Cache-Control', cacheControlHeaders['Cache-Control']);

      return redirectDocument(redirectUrl.toString(), {
        headers: responseHeaders,
      });
    } catch (err) {
      if (err instanceof AuthorizationError) {
        return validationError(
          { subaction: 'login', fieldErrors: { password: 'invalid-email-or-password' } },
          {},
          { headers: { ...cacheControlHeaders } },
        );
      }

      if (err instanceof LoginError) {
        return validationError({
          subaction: 'login',
          // keeping this generic to prevent targeted login attacks.
          // we should be handling all known status.
          fieldErrors: { password: 'invalid-email-or-password' },
        });
      }

      if (err instanceof AuthnRedisError) {
        // this error is thrown when the user's password is expired and there
        // was a problem caching their authn result in redis before sending
        // them to the expired password page. Typically if a user's password
        // has expired, we handle it by sending them to a page to change it.
        // Since the flow to send them to that page failed, we will return a
        // 'password-expired' error so that the page doesn't
        // crash, even though it won't really give them an option to reset their
        // password if this happens. This is more a stop-gap to help us identify
        // what the actual issue is with the redis caching so we can apply
        // a proper fix and make this unnecessary.
        // Hopefully if a user does run into this, they will at least be able
        // to call client services with a descriptive error message
        captureException(err);
        return validationError({
          subaction: 'login',
          fieldErrors: { password: 'your-password-has-expired' },
        });
      }

      context.log('Unknown error', err);

      throw new Error('Unknown error occurred.');
    }
  } else {
    return new Response(null, {
      status: 400,
      headers: { ...cacheControlHeaders },
    });
  }
}

export default function Index() {
  const loaderData = useLoaderData<typeof loader>();
  const actionData = useActionData<typeof action>();
  // Paths for moodys.com are specific to the locale & country code - we need to map the path for these pages
  const localePath = loaderData.locale === 'ja' ? 'ja/jp' : 'en/us';
  const isUserRemembered = loaderData.isUserRemembered;
  const defaultEmail = loaderData.defaultEmail || actionData?.email;

  const showPassword =
    loaderData.type === 'password' || actionData?.type === 'password' || !!actionData?.fieldErrors?.password;
  const { t } = useTranslationScope(['common-links']);

  useEffect(() => {
    if (showPassword) {
      document.querySelector<HTMLInputElement>('#password')?.focus();
    }
  }, [showPassword]);

  return (
    <>
      {showPassword ? (
        <>
          <PasswordForm email={defaultEmail} isUserRemembered={isUserRemembered} />
          <PreserveQueryLink className="text-link mt-8 text-nowrap" to="/forgot-password">
            {t('reset-your-password')}
          </PreserveQueryLink>
        </>
      ) : (
        <WebfingerForm isUserRemembered={isUserRemembered} defaultEmail={defaultEmail} />
      )}

      <div className="mt-8 flex flex-row gap-6">
        <a
          className="text-link text-nowrap"
          href={`https://www.moodys.com/web/${localePath}/legal/terms-of-use.html`}
          target="_blank"
          rel="noreferrer"
        >
          {t('terms-of-use')}
        </a>
        <a
          className="text-link text-nowrap"
          href={`https://www.moodys.com/web/${localePath}/legal/privacy-policy.html`}
          target="_blank"
          rel="noreferrer"
        >
          {t('privacy-policy')}
        </a>
        <PreserveQueryLink className="text-link text-nowrap" to="/help">
          {t('common-links:trouble-signing-in')}
        </PreserveQueryLink>
      </div>

      <div className="mt-2 text-sm">
        {t('dont-have-an-account') + ' '}
        <PreserveQueryLink className="text-link text-nowrap" to="/register">
          {t('sign-up')}
        </PreserveQueryLink>
      </div>
    </>
  );
}

function WebfingerForm({ isUserRemembered, defaultEmail }: { isUserRemembered: boolean; defaultEmail: string }) {
  const { t } = useTranslationScope();

  return (
    <ValidatedForm
      validator={authValidator}
      className="flex w-full flex-col"
      method="post"
      subaction="identify"
      id="identify-form"
      defaultValues={{ email: defaultEmail || '' }}
    >
      <ValidatedInput
        name="email"
        label={t('email')}
        id="identify-form-email"
        inputMode="email"
        type="email"
        autoComplete="username"
        autoFocus={true}
      />

      <label className="mt-4 flex flex-wrap items-center gap-1 text-sm" htmlFor="rememberMe">
        <input
          className="text-primary focus:outline-primary mr-2"
          id="rememberMe"
          name="rememberMe"
          type="checkbox"
          value="true"
          defaultChecked={isUserRemembered}
        />
        <span>{t('remember-me')}</span>
      </label>

      <SubmitButton className="mt-8" color="moodysBlue" id="next-btn">
        {t('next')}
      </SubmitButton>
    </ValidatedForm>
  );
}

function PasswordForm({ email, isUserRemembered }: { email: string; isUserRemembered: boolean }) {
  const { t } = useTranslationScope();

  return (
    <ValidatedForm
      validator={authValidator}
      className="flex w-full flex-col"
      method="post"
      subaction={'login'}
      id="password-form"
      defaultValues={{ email }}
    >
      <ValidatedInput
        name="email"
        label={t('email')}
        id="password-form-email"
        inputMode="email"
        type="email"
        autoComplete="username"
        readOnly={true}
      />

      <div className="mt-2 flex justify-end">
        {/* We want to do history replace here so that the user does not
            experience unexpected behavior when clicking the back button */}
        <PreserveQueryLink to="/" omit={['login_hint']} className="text-xs underline" replace>
          {t('use-a-different-account')}
        </PreserveQueryLink>
      </div>

      <ValidatedInput
        className="mt-4"
        label={t('password')}
        id="password"
        name="password"
        type="password"
        autoComplete="currentPassword"
        autoFocus
        required
      />

      <label className="mt-4 flex flex-wrap items-center gap-1 text-sm" htmlFor="rememberMe">
        <input
          className="text-primary focus:outline-primary mr-2"
          id="rememberMe"
          name="rememberMe"
          type="checkbox"
          value="true"
          defaultChecked={isUserRemembered}
        />
        <span>{t('remember-me')}</span>
      </label>

      <SubmitButton className="mt-8" color="moodysBlue" id="sign-in-btn">
        {t('sign-in')}
      </SubmitButton>
    </ValidatedForm>
  );
}
